import { reactive, watch } from '@vue/composition-api'
import useNotify from '../hooks/notify.js'

export default function useApiErrors( args = {} ){
  const { error } = useNotify()
  const errors = reactive({ message: '', errors : [] })
  const options = { dispatch: true, ...args }

  /**
   * Check if error exists by name or generic
   *
   * @param  string name
   * @return boolean
   */
  function hasError( name = null ){
    return name && !!errors.errors[ name ] || !name && !!errors.message
  }

  /**
   * Clear errors
   *
   * @return void
   */
  function clearError(){
    errors.message = ''
    errors.errors = []
  }

  /**
   * Set error by api response or exception
   *
   * @param  mixed response { message:'', errors:[] }
   * @return void
   */
  function setError( response ){
    errors.message = response.message
    errors.errors = response.errors || []
  }

  /**
   * Get Error by name or generic
   *
   * @param  string name
   * @return string
   */
  function getError( name = null ){
    if( !hasError( name ) ) return ''

    if( name != null ){
      return errors.errors[ name ].join( ', ' )
    }

    return getErrorMessage()
  }

  /**
   * Get Error message
   *
   * @return string
   */
  function getErrorMessage(){
    return errors.message
  }

  /**
   * Dispatch to notify message generic
   */
  if( options.dispatch ){
    watch( () => errors.message, ( value ) => {
      if( value ){
        error( getErrorMessage() )
      }
      else {
        clearError()
      }
    })
  }

  return {
    clearError,
    hasError,
    getError,
    getErrorMessage,
    setError,
  }
}
