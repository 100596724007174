import { ref } from '@vue/composition-api'

export default function useToggler( value = false ){
  const toggler = ref( value )

  function toggle(){
    toggler.value = !toggler.value
  }

  function on(){
    toggler.value = true
  }

  function off(){
    toggler.value = false
  }

  return {
    toggler,
    toggle,
    on,
    off,
  }
}
