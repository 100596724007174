<template>
  <q-card dark class="shadow-24" style="max-width: 96%; width: 400px">
    <q-card-section class="text-center text-white q-pb-none">
      <Logo style="height: 96px" />
    </q-card-section>

    <q-card-section class="q-px-lg">
      <q-form class="q-gutter-md" @submit.prevent="$emit('submit')">
        <slot />
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import Logo from '../Logo.vue'

export default {
  name: 'AuthCardForm',

  components: {
    Logo,
  },
}
</script>
