import Notify from 'quasar/src/plugins/Notify.js';
import merge from 'lodash/merge'

export default function useNotify(){

  function create( object ){
    return Notify.create( object )
  }

  function error( message, object = {} ){
    create( merge({
      type : 'negative',
      icon : 'mdi-alert-outline',
      message : message
    }, object ))
  }

  function success( message, object = {} ){
    create( merge({
      type : 'positive',
      icon : 'mdi-check-outline',
      message : message
    }, object ))
  }

  function info( message, object = {} ){
    create( merge({
      type : 'info',
      icon : 'mdi-alert-circle-outline',
      message : message
    }, object ))
  }

  function ongoing( message = 'Operazione in corso', object = {} ){
    return create( merge({
      type : 'ongoing',
      color: 'dark',
      message : message
    }, object ))
  }

  function update( notify, message = 'Operazione completata', object = {} ){
    notify( merge({
      type: 'positive',
      icon: 'mdi-check-bold',
      message : message
    }, object ))
  }

  return {
    create,
    error,
    info,
    success,
    ongoing,
    update,
  }
}
